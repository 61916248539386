<template>
    <div>
        <div class="holder">
            <div class="addNewUnit">
                <div class="unit-text-button">
                <h5>Add Unit(s)</h5>
                <button class="btn" @click="addForm()"><span>+</span> Add unit</button>
                </div>
                <h6>Add the individual units within this property. Each unit can be tracked and updated separately.</h6>
            </div>
              
            <div v-for="(form, index) in forms" :key="index">
                <div class="unit-count-holder">
                <h3>Unit {{ index + 1 }}</h3>
                <div v-if="forms.length > 1 && index != 0">
                    <!-- <small>Save</small> -->
                    <img style="cursor: pointer;" @click="removeForm(index)" src="../assets/icons/trash.svg" alt="delete">
                </div>
                </div>
    
                <form @submit.prevent="submitForm(index)">
                <div class="input-group">
                    <div class="row">
                    <div class="col-md-4 form-group">
                        <label for="name">Unit name:</label>
                        <select class="form-control dynamic-input" id="bathroom" v-model="form.unit_name">
                            <option  value="" disabled>Select</option>
                            <option
                            v-for="(category, index) in propertyCategory"
                            :key="index"
                            :value="category"
                            >{{ category }}</option
                            >
                    
                        </select>
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="price">Unit Amount:</label>
                        <input class="form-control dynamic-input" id="price" type="text" v-model="form.price" @input="formatPrice(index)" placeholder="Enter unit amount" />  
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="bedrooms">Number of bedrooms:</label>
                        <select class="form-control dynamic-input" id="bedroom" v-model="form.bedrooms">
                        <option  value="" selected disabled>Select</option>
                        <option  value="1">1</option>
                        <option value="2">2</option>
                        <option class="3">3</option>
                        <option class="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        </select>
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="bedrooms">Number of bathrooms:</label>
                        <select class="form-control dynamic-input" id="bathroom" v-model="form.bathrooms">
                        <option  value="" selected disabled>Select</option>
                        <option  value="1">1</option>
                        <option value="2">2</option>
                        <option class="3">3</option>
                        <option class="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        </select>
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="bedrooms">Number of toilets:</label>
                        <select class="form-control dynamic-input" id="toilets" v-model="form.toilets">
                        <option  value="" selected disabled>Select</option>
                        <option  value="1">1</option>
                        <option value="2">2</option>
                        <option class="3">3</option>
                        <option class="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        </select>
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="status">Status:</label>
                        <select class="form-control dynamic-input" name="status" id="status" v-model="form.status">
                        <option value="" disabled>Select status</option>
                        <option
                            v-for="status in propertyStatuses"
                            :key="status"
                            :value="status"
                            >{{ status }}</option
                        >
                        </select>
                        
                    </div>
                
                    </div>
                </div>
                </form>
            </div>
            <div class="col-md-4 form-group pl-0">
                <label for="bedrooms">Add more units (optional): <span>{{ forms.length }}</span></label>
                <input class="form-control dynamic-input" id="unitNumber" type="number" v-model.number="duplicateFormNumber" placeholder="Add more units..." />
            </div>

            
            <div class="green-yellow-btn">
                <button class="btn cancel" @click="backToLandlordPropertyList()">Back</button>
                <button class="btn save" @click="addUnitToProperty" :disabled="submittingUnit">
                {{ submittingUnit ? 'submitting...' : 'Add Unit' }}
                </button>
            </div>
        </div>
        
    </div>
</template>


<script>
    import properties from "../services/properties";

    export default {
        name: "AddPropertyUnit",
        
        data() {
            return {
                loading: false,
                updatingUnitLoading: false,
                submittingUnit: false,
                loadingStatus: false,
                propertyCategory: [],
                propertyStatuses: [],
                forms: [
                    {
                    unit_name: '',
                    price: '',
                    bedrooms: '',
                    bathrooms: '',
                    toilets: '',
                    status: '',
                    },
                ],
                duplicateFormNumber: 0,
            }
        },
        mounted() {
            this.getPropCategory();
            this.getPropertyStatuses();
        },
        
        methods: {
            backToLandlordPropertyList() {
              this.$router.go(-1);
            }, 

            async addUnitToProperty() {
                this.submittingUnit = true;
                const batch = this.$route.params.id;
                let updatedArray = this.forms.map((item) => ({
                    ...item,
                    price: item.price.replace(/,/g, ""),
                }));
                const body = {
                    units_objects: updatedArray,
                    batch: batch,
                }
                
                try {
                    await  properties.addNewUnit(JSON.stringify(body)).then((data) => {
                        this.submittingUnit = false;
                        if (data.success) {
                            this.$toast.success("SUCCESS, UNIT ADDED TO PROPERTY");
                            this.$router.go(-1);
                        }
                        else {
                            this.$toast.error("ERROR");
                        }
                    }).catch((err) => {
                        console.log(err);
                        this.submittingUnit = false;
                    });
                } finally {
                    // console.log("closed");
                }
            },

            formatPrice(index) {
                // Remove existing commas
                // const unformatedPrice = this.forms[index].price.replace(/,/g, "");
                // // Add commas back in
                // this.forms[index].price = new Intl.NumberFormat().format(unformatedPrice);

                // let value = this.forms[index].price.replace(/,/g, ""); // Remove existing commas
                // if (!isNaN(value)) {
                //     this.forms[index].price = new Intl.NumberFormat().format(value); // Format with commas
                // } else {
                //     this.forms[index].price = ""; // Reset invalid input
                // }

                const unformatedPrice = this.forms[index].price.replace(/,/g, "");
                // Add commas back in
                this.forms[index].price = new Intl.NumberFormat().format(unformatedPrice);
            },
            formatAllPrices() {
                this.forms = this.forms.map((item) => ({
                    ...item,
                    price: new Intl.NumberFormat().format(item.price.replace(/,/g, "")),
                }));
            },
            addForm() {   

                const lastForm = this.forms[this.forms.length - 1]; // Get the last form
                // Validate if all fields of the last form are filled
                if (!lastForm.unit_name || !lastForm.price || !lastForm.bedrooms || !lastForm.bathrooms || !lastForm.toilets || !lastForm.status) {
                    this.$toast.error("Please fill in all fields in the unit form before adding a new form.");
                    this.invalidFormArray = true;
                    return;
                }
                
                // Add a new form if validation passes
                this.forms.push({ unit_name: '', price: '', bedrooms: '',  bathrooms: '', toilets: '', status: ''});
            },
            removeForm(index) {
                this.forms.splice(index, 1); // Remove 1 element at the given index
            },
            duplicateFormData(newData, oldData) {
                const lastForm = this.forms[this.forms.length - 1]; 
                if (!lastForm.unit_name || !lastForm.price || !lastForm.bedrooms|| !lastForm.bathrooms || !lastForm.toilets || !lastForm.status) {
                    this.$toast.error("Please fill in all fields in the unit form before adding a new form.");
                    return;
                }
                
                if (newData < 1) return;
                if (newData > oldData) {
                    // Add new objects to the array
                    const additionalObjects = Array.from(
                    { length: newData - oldData },
                    () => ({ 
                        unit_name: this.forms[0].unit_name,
                        price: this.forms[0].price,
                        bedrooms: this.forms[0].bedrooms,
                        bathrooms: this.forms[0].bathrooms,
                        toilets: this.forms[0].toilets,
                        status: this.forms[0].status,
                    })
                    );
                    this.forms.push(...additionalObjects);
                } else if (newData < oldData) {
                    // Remove excess objects from the array
                    this.forms.splice(newData);
                }
                // const duplicatedArray = this.forms.flatMap(item => Array(Number(`${this.duplicateFormNumber}`)).fill({ ...item }));
                // this.forms = this.duplicatedArray;
            },
            getPropCategory() {
                 properties.fetchPropCategory().then((data) => {
                    if (data.success) {
                        // this.propertyCategory = data.categories;
                        this.propertyCategory = data.updated_category;
                    }
                    // this.loadingLocation = false;
                }).catch((err) => {
                    console.log(err);
                });
            },
            getPropertyStatuses() {
                this.loadingStatus = true;
                properties.fetchPropertyStatuses().then((data) => {
                    this.propertyStatuses = data.propertystatus;
                    this.loadingStatus = false;
                });
            },
        },
        watch: {
            duplicateFormNumber(newData, oldData) {
                if (newData) {
                this.duplicateFormData(newData, oldData);
                    
                }
            },
        }
    }
</script>


<style lang="scss" scoped>

    $primary: #0033EA;
    $white: #ffffff;
    $asheGrey: #555C74;
    $offBlack: #2B3352;

    .holder {
        background: #ffffff;
        border-radius: 8px;
        padding: 15px;
    }



    // Dynamic input starts here
    .unit-count-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #2B3352;
    }
    button {
        outline: 0;
        box-shadow: none;
        font-family: Lato;
        font-size: 16px;
        color: #555C74;
    }
    }
    .unit-text-button {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    h6 {
        font-family: Lato;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: #2B3352;
    }
    button {
        font-family: Lato;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: #0033ea;
        border-bottom: 1px solid #0033ea;
        border-radius: 0;
        box-shadow: none;
        outline: 0;
        padding-left: 0;
        padding-right: 0;
    }
    }
    .addNewUnit {
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #555C74;
        margin-top: 10px;
    }
    }

    .dynamic-input {
    padding: 1rem 10px !important;
    border: 1px solid #d2d3d3;
    border-radius: 6px;
    color: #57595a;
    font-size: 1rem;
    font-family: Lato;
    height: inherit;
    outline: 0;
    box-shadow: none;
    // height: 48px;
    }
    .prop-reviw-holder {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20;
    img {
        cursor: pointer;
    }
    }
    .general-info-content {
    div {
        margin-bottom: 10px;
        h5 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #2B3352;
        overflow-wrap: break-word;
        }
        h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #555C74;
        overflow-wrap: break-word;
        }
        h6:last-child {
        margin-bottom: 0;
        }
    }
    }

    .info-holder {
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    padding: 20px 15px;
    }
    .review-top-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    h5 {
        font-family: Lato;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        color: #2B3352;

    }
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #0033EA;
    }
    }
    .no-prop-add {
    margin-top: 30px;
    border: 1px dashed #E4E7EC;
    min-height: 128px;
    background: #FDFDFD;
    text-align: center;
    padding: 20px;
    cursor: pointer;
    h2 {
        color: #555C74;
        font-size: 50px;
        font-family: Lato;
    }
    h6 {
        color: #555C74;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
    }
    }

    .submit-btn:disabled {
    background-color: lightgray;
    cursor: not-allowed;
    }

    .btn-container {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    }

    .empty-state {
    text-align: center;
    background: #ffffff;
    padding: 20px 15px;
    h5 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: #2B3352;
    }
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #555C74;
    }
    }

    .green-yellow-btn {
        margin-top: 40px;
        button {
            font-family: Lato;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            min-height: 48px;
            min-width: 106px;
            outline: 0;
            box-shadow: none;
        }
        .save {
            background: #2BC300;
        }
        .cancel {
            background: #EB0000;
            margin-right: 20px;
        }
    }

// Dynamic input ends here

</style>